.margin_cr_mobile{
    margin-top: 25%;
}
.login-container-account-mobile {
    font-family: 'cookie';
    max-width: 55%;
    margin-left: 4%;
    padding: 20px 30px;
    border: none;
    border-radius: 35px; 
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 450px;
    box-shadow: 0 4px 80px rgba(250, 250, 250, 0.7);
    background-color: white;
    padding-left: 70px;
    padding-right: 70px;
}

.login-container-account-mobile input[type="text"],
.login-container-account-mobile input[type="email"],
.login-container-account-mobile input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: "cookie", cursive;
}

.login-container-account-mobile button {
    margin-top: 7px;
    width: 100%;
    padding: 10px;
    background-color: #689E55;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "cookie", cursive;
    font-size: 20px;
    text-align: center;
    margin-bottom: 8px;
}
  
.login-container-account-mobile button:hover {
    background-color: #7bbb5d;
}
.c-text-mobile{
    text-align: left;
    margin-bottom: 3px;
}