/* h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: disc;
}

li {
  margin: 0;
  padding: 0;
}
ol{
  margin: 0;
}
h1 + ul, h2 + ul, h3 + ul, h4 + ul, h5 + ul, h6 + ul {
  margin-top: 0;
}

ul > li {
  margin-top: 0;
} */

body {
  margin: 0; 
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "cookie";
  text-align: center;
  background-image: url('../BackGround4.png');
  background-color: #2a2a2a;
  background-repeat: no-repeat;
  /* justify-items: center; */
  /* background-size: cover;
  background-position: center; */
  background-size: 200vh;
}

.logo1 {
  width: 100%;
  margin-top: 20%;
}

.chat-container {
  font-family: 'cookie';
  max-width: 100%;
  /* margin: 1% auto; */
  border: none;
  /* border-radius: 35px; */
  background-color: rgba(250, 250, 250, 0.8);
  display: flex;
  flex-direction: row;
  height: 100vh;
  color: #2a2a2a;
  position: relative;
  box-shadow: 0 4px 80px rgba(11, 11, 11, 1); 
  scrollbar-width: thin;
  scrollbar-color: #56764B #e9EFDF;
}
.dark-mode .chat-container{
  scrollbar-color: #2b2b2b #3a3a3a;
  transition: filter 2s;
}

.light-mode .left-half {
  background-color: #56764B;
  transition: filter 2s;
}

.dark-mode .left-half {
  background-color: #2a2a2a;
  transition: filter 2s;
}
.light-mode .right-half {
  background-color: rgb(233, 239, 223,1);
  transition: filter 2s;
}

.dark-mode .right-half {
  background-color: #3a3a3a;
  transition: filter 2s;
}

.left-half {
  width: 15vh;
  left: 0;
  height: 100vh;
  align-items: flex-start;
  text-align: center;
  flex-direction: column;
}
.save-container{
  /* height: 10%; */
  display: flex;
  align-items: center;
  /* height: 100px; */
}
.right-half .save-container img{
  position: inherit;
  height: 10px;
  background-color: #e9EFDF;
}
.right-half .save-container img:hover{
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 0;

}
.right-half {
  flex-grow: 1;
  width: 100%;
  /* height: 85vh; */
  display: flex;
  /* align-items: center; */
  align-items: flex-start;
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  overflow: hidden;
  flex-direction: column;
  background-color: #2a2a2a;
}

.chat {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  height: 100%;
}

.right-half input[type="text"] {
  width: calc(100% - 40vh);
  padding: 10px;
  border: 1px solid white;
  border-radius: 15px;
  box-sizing: border-box;
  font-family: "cookie", cursive;
  bottom: 0;
  position: absolute;
  text-align: left;
  margin-bottom: 30px;
  background-color: white;
  left: 14%;
  /* margin-right: 2%; */
  display: flex;
  font-size: 16px;
}

.light-mode .right-half input[type="text"]:focus {
  border-color: white;
  box-shadow: 0 0 30px rgba(222, 255, 238, 0.897);
  outline: none;
}

.dark-mode .right-half input[type="text"]{
  border: 1px solid #9b9b9b;
  background-color: #8b8b8b;
}

.dark-mode .right-half input[type="text"]:focus {
  border-color: #8b8b8b;
  box-shadow: 0 0 30px rgba(173, 172, 172, 0.782);
  outline: none;
}

.right-half img {
  right: 0;
  margin-right: 40px;
  bottom: 30px;
  position: absolute;
  width: 43px;
}

.right-half img:hover {
  border-radius: 100px;
  filter: opacity(80%);
}
.light-mode .icon_chat_chating{
  background-color: #1D3708;
  transition: filter 1.5s ease, opacity 1.5s ease;
}
.dark-mode .icon_chat_chating{
  background-color: #1D3708!important;
  transition: filter 1.5s ease, opacity 1.5s ease;
}
.icon_chat_chating.selected,
.icon_archive_chating.selected
{
  transition: filter 1.5s ease, opacity 1.5s ease;
}
.icon_mode {
  display: flex;
  align-items: center;
}

.light-mode .icon_chat_chating {
  padding: 30px;
  transition: filter 1.5s ease, opacity 1.5s ease;
  background-color: rgba(20,37,8);
}
.dark-mode .icon_chat_chating {
  background-color: #1a1a1a!important;
  padding: 30px;
  transition: filter 1.5s ease, opacity 1.5s ease;
}
.light-mode .icon_chat_chating:hover {
  filter:none;
}
.dark-mode .icon_chat_chating:hover {
  filter: none;
}

.light-mode .icon_archive_chating {
  transition: filter 1.5s ease, opacity 1.5s ease;
  padding: 25px;
}
.dark-mode .icon_archive_chating {
  transition: filter 1.5s ease, opacity 1.5s ease;
  padding: 25px;
}
.light-mode .icon_archive_chating:hover {
  background-size: 10%;
  padding:25px
}
.icon_archive_Recipe{
  background-color: #1a1a1a!important;
}
.dark-mode .icon_archive_chating:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
}
.icon_settings{
  position : absolute;
  transition: filter 1.5s ease, opacity 1.5s ease;
  padding: 30px;
  margin-left: 10px;
  margin-top: 300px;
  bottom:100px;
}
.light-mode .icon_settings:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
  padding: 30px;
}
.dark-mode .icon_settings:hover{
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
  padding: 30px;
}

.icon_exit {
  position: absolute;
  bottom: 0px;
  padding: 30px;
  margin-left: 10px;
  /* display: inline-block; */
  transition: filter 1.5s ease, opacity 1.5s ease;
}
.light-mode .icon_exit:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
}

.dark-mode .icon_exit:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
}

.chating-container {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: 10px;
  margin-left: 15px;
  padding: 30px 30px 0 30px;
}

.light-mode .input-container {
  background-color: #e9EFDF;
  transition: filter 2s;
}

.dark-mode .input-container {
  background-color: #3a3a3a;
  transition: filter 2s;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 1%;
  padding: 0px 20px;
  flex-shrink: 0;
}
.input-container [type='text']{
  margin-left: -3%;
}
.input-container img{
  right: 3%;
}
.messages {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 90%;
  margin-bottom: 10%;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.light-mode .message.user {
  background-color: #56764B;
  transition: filter 2s;
}

.dark-mode .message.user {
  background-color: #2a2a2a;
  color:rgb(237, 237, 237);
  transition: filter 2s;
}
.text-guide {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  padding-bottom: 2px;
}
.light-mode .text-guide{
  color: #bbc2b1;
}
.dark-mode .text-guide{
  color: #7b7777;
}
.message.user {
  position: relative;
  margin-left: 60%;
  right: 3%;
  text-align: left;
  padding: 13px;
  background-color: #56764B;
  border-radius: 20px;
  font-size: 18px;
  width: 35%;
  max-width: 40%;
  color: white;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.light-mode .message.ai {
  background-color: #e9EFDF;
  transition: filter 2s;
}

.dark-mode .message.ai {
  background-color: #3a3a3a;
  color: white!important;
  transition: filter 2s;
}

.message.ai {
  margin-top: 5%;
  margin-bottom: 10px;
  padding: 13px;
  border-radius: 20px;
  font-size: 20px;
  width: 90%;
  max-width: none;
  color: black;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.dark-mode .spinner {
  border-left-color: aliceblue;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); 
  border-left-color: #22a6b3; 
  border-radius: 50%; 
  width: 24px; 
  height: 24px; 
  animation: spin 1s linear infinite; 
  display: inline-block; 
  margin-top: 5px; 
  background: transparent;
  box-shadow: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.switch {
  width: 20px;
  height: 34px;
}
.switch span{
  margin-left: 10px;
  font-size: 28px;
  margin-right: 10px;
  position: absolute;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 80px;
  right: 0;
  bottom: 0;
  background-color: #e9EFDF;
  transition: 0.4s;
  border-radius: 34px;
}
.slider-label {
  margin-right: 10px;
  color: #fff;
  font-size: 14px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  top:2px;
  border: 2px solid #2b2b2b;
  background-color: aliceblue;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #8b8b8b;
}

input:checked + .slider:before {
  transform: translateX(42px);
  background-color: #2B2B2B;
  transition: 0.4s;
  border-radius: 50%;
  /* background-image: url('../dark.png');
  background-size: 22px 22px;
  background-position:center;
  background-repeat: no-repeat; */
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
