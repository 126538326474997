.chat-container-mobile {
  font-family: 'cookie';
  /* max-width: cover;
  max-height: cover; */
  background-color: white !important;
  display: flex;
  flex-direction: column;
  color: black;
  scrollbar-width: thin;
  scrollbar-color: #56764B #e9EFDF;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  height: 100%;
}
.chat-container-mobile input[type="text"] {
  /* border: none; */
  margin-top: 10%;
  border-radius: 10px;
  padding-left: 2%;
  font-family: "cookie";
  text-align: left;
  font-size: 16px;
  position: absolute;
  width: 80%;
  bottom: 2%;
  left: 3%;
  
}
.input-container-mobile img {
  right: 2%;
  bottom: 2%;
  position: absolute;
  width: 35px;
}

.input-container-mobile img:hover {
  border-radius: 100px;
}
.dark-mode .chat-container-mobile {
  scrollbar-color: #4b4b4b #2b2b2b;
  transition: filter 2s;
}
.light-mode .top-container-mobile {
  background-color: white !important;
  transition: filter 2s;
}

.top-container-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px; 
  padding: 0 10px;
  box-sizing: border-box;
  color: #56764B;
  background-color: #fff;
}

.icon_menu_mobile img {
  width: 30px;
  height: 30px;
}

.center-text {
  font-size: 18px;
  font-weight: bold;
}

.menu-bar {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding-bottom: 20px;
}

.menu-bar.show {
  display: block;
}

.chat-mobile, .mobile-recipe {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
  height: 30px;
  border-radius: 10px;
  border: none!important;
  cursor: pointer;
  text-align: left;
  justify-content: flex-start;
}

.chat-mobile {
  text-align: left;
  margin-top: 10%;
  background-color: #e9efdf;
}

.chat-mobile img, .mobile-recipe img {
  width: 30px!important;
  height: 30px!important;
  margin-right: 10px;
  left: 10%;
  position: absolute;
}

.chat-mobile h2, .mobile-recipe h2 {
  color: #1d370b;
  margin: 0;
  font-size: 20px;
  text-align: left;
  left: 25%;
  position: absolute;
}

.mobile-recipe {
  background-color: white;
  border: 1px solid #e9efdf;
}

.bottom-section {
  position: absolute;
  bottom : 30px;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px; 
  padding: 0 10px;
  box-sizing: border-box;
  color: #1d370b;
  background-color: white!important;
}

.mobile_setting{
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
}

.mobile_setting img{
  width: 40px!important;
  height: 40px!important;
  left: 5%;
  bottom: 60%;
  position: absolute;
}
.mobile_setting h2{
  left: 25%;
  bottom: 95%;
  position: absolute;
}
.icon-exit_mobile h2 {
  left: 25%;
  bottom: 5%;
  position: absolute;
  color: #1d370b!important;
}
.icon-exit_mobile img{
  width: 40px!important;
  height: 40px!important;
  left: 5%;
  bottom: -30%;
  position: absolute;
}
.message-mobile{
  flex-grow: 1;
  padding: 10px;
  max-height: fit-content;
}
.messages-mobile {
  flex-grow: 1;
  overflow-y: auto; 
  overflow-x: hidden; 
  padding: 10px;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.ai-message-container-mobile{
  margin-top: -10px;
}
.message-mobile.user {
  position: relative;
  margin-left: 70%;
  word-break: break-word;
  max-height: fit-content;
  right: 10%;
  text-align: left;
  padding-left: 3%;
  padding-right: 3%;
  background-color: rgb(58, 109, 39)!important;
  border-radius: 20px;
  font-size: 14px;
  width: 30%;
  max-width: 30%;
  color: white;
}
.message-mobile.ai .ai-message-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-mobile.ai .ai-icon-container {
  padding-left: 5%;
  flex-shrink: 0;
  margin-right: 10px;
}

.message-mobile.ai .ai-icon {
  border: 1px solid black;
  border-radius: 50px;
  padding-left: 3%;
  width: 30px;
  height: 30px;
}

.message-mobile.ai .ai-message-container {
  flex-grow: 1;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.message-mobile.ai {
  bottom: 10%;
  text-align: left;
  padding-left: 0%;
  padding-right: 10%;
  border-radius: 20px;
  font-size: 15px;
  width: 90%;
  max-width: 80%;
  color: black;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.dark-mode .right-half-Recipe{
  color: white;
}

.light-mode .chat-container-mobile input[type="text"]:focus {
  /* border-color: white; */
  box-shadow: 0 0 30px rgba(222, 255, 238, 0.89);
  
  /* outline: none; */
}

/* .right-half img {
  right: 0;
  margin-right: 7%;
  bottom: 30px;
  position: absolute;
  width: 43px;
}

.right-half img:hover {
  border-radius: 100px;
  filter: opacity(80%);
} */
.menu_mobile {
  display: flex;
  align-items: auto;
}
.icon_menu_mobile img {
  margin-top: 15px!important;
  background-color: white;
  cursor: pointer;
  height: 15px!important;
  width: 30px!important;
  margin-right: -20px;
}
.center-text{
  margin-top: 10px;
  position: relative;
  flex-grow: 1;
  text-align: center;
  font-size: 25px; 
  color: #56764B!important;
}
.line-bar{
  width: 95%;
  background:#56764B;
  border: none;
  border-radius: 30px;
  height: 4px;
}
.icon_mode {
  position: relative;
  margin-top: 10px;
}
/* .icon_chat:hover {
  margin-top: 30px;
  transition: filter 1.5s ease, opacity 1.5s ease;
}
.light-mode .icon_chat:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
}
.dark-mode .icon_chat:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
} */
/* 
.light-mode .icon_settings:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
}
.dark-mode .icon_settings:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
} */
.light-mode .input-container-mobile {
  height: 8vh;
  border-radius: 80px;
  /* height: 8vh!important; */
  transition: filter 2s;
}
.dark-mode .input-container-mobile {
  background-color: #2B2B2B;
  transition: filter 2s;
}
.input-container-mobile [type='text']{
  border: 1px solid black!important;
  border-radius: 30px!important;
  transition: filter 2s;
}
.menu-bar {
  /* position: fixed; */
  top: 0;
  height: 100%;
  width: 80%;
  background-color: white!important;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-bar.show {
  transform: translateX(0);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9; 
  display: none;
}
.overlay.show {
  display: block;
}
.spinner-mobile{
  border: 4px solid rgba(0, 0, 0, 0.1); 
  border-left-color: #22a6b3; 
  border-radius: 50%; 
  width: 24px; 
  height: 24px; 
  animation: spin 1s linear infinite; 
  display: inline-block; 
  margin-top: 5px; 
  margin-left: 5%;
  background: transparent;
  box-shadow: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 768px) {
  body{
    background-color: white;
  }
  .chat-container-mobile input[type="text"] {
    margin-top: 10%;
    border-radius: 100px;
    font-family: "cookie";
    text-align: left;
    font-size: 16px;
    position: absolute;
    width: 85%;
    bottom: 2%;
    left: 3%;
    overflow-y: hidden;
    /* border: 10px solid black!important; */
  }
  .input-container-mobile img {
    right: 3%;
    bottom: 2%;
    position: absolute;
    width: 35px;
  }
  .top-container-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    height: 5%; 
    /* padding: 2.5% 10px;  */
    box-sizing: border-box;
    /* position: relative; */
    overflow: hidden;
    color: rgba(255, 255, 238, 1);
  }
}

