.margin_mobile{
    margin-top: 10%;
}
.title_mobile{
    font-size: 22px;
}
.pass_mobile a {
    text-decoration: none;
    color: inherit;
    font-size:13px;
}
.pass_p_mobile{
    margin-right: 5px;
}
.pass_c_mobile{
    margin-left: 5px;
    margin-right: 5px;
}
.pass_p_mobile:hover{
    color: gray;
}
.pass_c_mobile:hover{
    color: gray;
}
.left-align {
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 3px;
}
.pass_e_mobile{
    margin-left: 5px;
}
.pass_e_mobile:hover{
    color: gray;
}

@font-face {
    font-family: "cookie";         
    src: url("../fonts/CookieRun.woff");
    font-display: swap;
  }
.login-container_mobile {
    font-family: 'cookie';
    max-width: 80%;
    margin-left: 4%;
    padding: 20px 20px;
    border: none;
    border-radius: 35px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 560px;
    box-shadow: 0 4px 80px rgba(250, 250, 250, 0.7);
    background-color: white;
}

.logo_mobile {
    width: 100px;
    height: auto; 
    margin: 0 auto;
}
.login-container_mobile h1 {
    text-align: center;
    margin-bottom: 20px;
}
.login-container_mobile input[type="text"],
.login-container_mobile input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: "cookie", cursive;
}
.login-container_mobile button {
    width: 100%;
    padding: 10px;
    background-color: #689E55;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "cookie", cursive;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
}
  
.login-container_mobile button:hover {
    background-color: #7bbb5d;
}
.pass_mobile{
    margin-top: 13px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 12px;
}
/* 13인치 아이패드 세로 화면 */
@media only screen and (min-width: 810px) and (max-width: 834px) and (min-height: 1024px) and (max-height: 2048px) {

}
/* 13인치 아이패드 가로화면 */
@media only screen and (min-width: 1024px) and (max-width: 2048px) and (min-height: 810px) and (max-height: 834px) and (orientation: landscape) {
    /* 가로 모드 스타일 정의 */
}

/* 11인치 아이패드 세로화면 */
@media only screen and (min-width: 768px) and (max-width: 834px) and (min-height: 1024px) and (max-height: 2388px) {
.login-container_mobile{
    font-family: 'cookie';
    max-width: 30%;
    margin: auto;
    padding: 10px 20px;
    border: none;
    border-radius: 35px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 560px;
    box-shadow: 0 4px 80px rgba(250, 250, 250, 0.7);
    background-color: white;
    padding-left: 80px;
    padding-right: 80px;
}
.login-container_mobile input[type="text"],
.login-container_mobile input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: "cookie", cursive;
}
.login-container_mobile button{
    width: 100%;
    padding: 10px;
    background-color: #689E55;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "cookie", cursive;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
}
.g_img{
    width: 100%;
}
}
/* 11인치 아이패드 가로 화면 */
@media only screen and (min-width: 1024px) and (max-width: 2388px) and (min-height: 768px) and (max-height: 834px) and (orientation: landscape) {
    .login-container_mobile{
    font-family: 'cookie';
    max-width: 30%;
    margin: auto;
    padding: 10px 20px;
    border: none;
    border-radius: 35px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 560px;
    box-shadow: 0 4px 80px rgba(250, 250, 250, 0.7);
    background-color: white;
    padding-left: 80px;
    padding-right: 80px;
}
.login-container_mobile input[type="text"],
.login-container_mobile input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: "cookie", cursive;
}
.login-container_mobile button{
    width: 100%;
    padding: 10px;
    background-color: #689E55;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: "cookie", cursive;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
}
}