.chat-container-mobile {
  font-family: 'cookie';
  max-width: cover;
  max-height: cover;
  border: none;
  background-color: rgba(233, 239, 223, 0.2);
  display: flex;
  flex-direction: column;
  color: black;
  scrollbar-width: thin;
  scrollbar-color: #56764B #e9EFDF;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  /* padding: 0; */
  height: 100%;
}
.chat-container-mobile input[type="text"] {
  border: none;
  margin-top: 10%;
  border-radius: 10px;
  padding-left: 2%;
  font-family: "cookie";
  text-align: left;
  font-size: 16px;
  position: absolute;
  width: 80%;
  bottom: 2%;
  left: 3%;
}
.input-container-mobile img {
  right: 2%;
  bottom: 2%;
  position: absolute;
  width: 35px;
}

.input-container-mobile img:hover {
  border-radius: 100px;
  filter: opacity(80%);
}
.dark-mode .chat-container-mobile {
  scrollbar-color: #4b4b4b #2b2b2b;
  transition: filter 2s;
}
.light-mode .top-container-mobile {
  background-color: rgb(58, 109, 39,0.6);
  transition: filter 2s;
}

.top-container-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  height: 8%; 
  padding: 2.5% 10px; 
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  color: rgba(255, 255, 238, 1);
}
.main_icon_mobile img{
    cursor: pointer;
    height: 40px;
    width: 40px;
}
.bottom-container-mobile {
  display: flex;
  height: 100vh;
  max-height: 100%;
  overflow-y: auto; 
  flex-direction: column;
  overflow-x: hidden;
  background-color: rgba(233, 239, 223, 0.2);;

}

.message-mobile{
  flex-grow: 1;
  padding: 10px;
  max-height: fit-content;
}
.messages-mobile {
  flex-grow: 1;
  overflow-y: auto; 
  overflow-x: hidden; 
  padding: 10px;
  flex-direction: column;
  font-size: 10px;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.message-mobile.user {
  position: relative;
  margin-left: 70%;
  word-break: break-word;
  max-height: fit-content;
  right: 10%;
  text-align: left;
  padding-left: 3%;
  padding-right: 3%;
  background-color: rgb(58, 109, 39,0.7);
  border-radius: 20px;
  font-size: 14px;
  width: 30%;
  max-width: 30%;
  color: white;
}
.message-mobile.ai .ai-message-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-mobile.ai .ai-icon-container {
  padding-left: 5%;
  flex-shrink: 0;
  margin-right: 10px;
}

.message-mobile.ai .ai-icon {
  padding-left: 3%;
  width: 30px;
  height: 30px;
}

.message-mobile.ai .ai-message-container {
  flex-grow: 1;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.message-mobile.ai {
  bottom: 10%;
  text-align: left;
  margin-top: 2%;
  padding-left: 0%;
  padding-right: 10%;
  border-radius: 20px;
  font-size: 15px;
  width: 90%;
  max-width: 80%;
  color: black;
  word-wrap: break-word;
  white-space: pre-wrap;
}


.light-mode .chat-container-mobile input[type="text"]:focus {
  border-color: white;
  box-shadow: 0 0 30px rgba(222, 255, 238, 0.89);
  outline: none;
}

/* .right-half img {
  right: 0;
  margin-right: 7%;
  bottom: 30px;
  position: absolute;
  width: 43px;
}

.right-half img:hover {
  border-radius: 100px;
  filter: opacity(80%);
} */
.menu_mobile {
  display: flex;
  align-items: auto;
}
.icon_menu_mobile img {
  cursor: pointer;
  height: 35px;
  width: 35px;
  margin-right: -20px;
}
.center-text{
  position: relative;
  flex-grow: 1;
  text-align: center;
  font-size: 25px; 
  color: white;
}
.icon_mode {
  position: relative;
  margin-top: 10px;
}
/* .icon_chat:hover {
  margin-top: 30px;
  transition: filter 1.5s ease, opacity 1.5s ease;
}
.light-mode .icon_chat:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
}
.dark-mode .icon_chat:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
} */
.icon_archive {
  transition: filter 1.5s ease, opacity 1.5s ease;
  /* margin-top: 30px; */
}

.light-mode .icon_archive:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
}
.dark-mode .icon_archive:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
}
/* .icon_settings {
  position: relative;
  margin-top: 230px;
  display: inline-block;
  transition: filter 1.5s ease, opacity 1.5s ease;
} */
/* 
.light-mode .icon_settings:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
}
.dark-mode .icon_settings:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
} */

.icon_exit_mobile{
  display: block;
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
  transition: filter 1.5s ease, opacity 1.5s ease;
}

.icon_exit:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
}
.light-mode .input-container-mobile {
  height: 8vh;
  background-color: rgba(233, 239, 223, 0.2);
  transition: filter 2s;
}
.dark-mode .input-container-mobile {
  background-color: #2B2B2B;
  transition: filter 2s;
}
.menu-bar {
  position: fixed;
  top: 0;
  height: 100%;
  width: 80%;
  background-color: rgba(255, 255, 238, 0.9);
  /* box-shadow: 40px 0 5px rgba(0,0,0,0.5); */
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-bar.show {
  transform: translateX(0);
}

.menu-bar img {
  display: block;
  margin: 20px auto;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.menu-bar .bottom-section {
  margin-right: 3%;
  max-width: 100%;
  margin-left: 3%;
  margin-bottom: 3%;
  border-radius: 20px;
  height: 10%;
  background-color: rgba(255, 255, 238); 
  justify-items: right;
  display: flex;
  justify-content: flex-end; 
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9; 
  display: none;
}
.overlay.show {
  display: block;
}
.spinner-mobile{
  border: 4px solid rgba(0, 0, 0, 0.1); 
  border-left-color: #22a6b3; 
  border-radius: 50%; 
  width: 24px; 
  height: 24px; 
  animation: spin 1s linear infinite; 
  display: inline-block; 
  margin-top: 5px; 
  margin-left: 5%;
  background: transparent;
  box-shadow: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 768px) {
  body{
    background-color: rgb(134, 128, 106,0.9);
  }
  .chat-container-mobile input[type="text"] {
    margin-top: 10%;
    border-radius: 10px;
    font-family: "cookie";
    text-align: left;
    font-size: 16px;
    position: absolute;
    width: 85%;
    bottom: 2%;
    left: 3%;
    overflow-y: hidden;
  }
  .input-container-mobile img {
    right: 3%;
    bottom: 2%;
    position: absolute;
    width: 35px;
  }
  .top-container-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    height: 5%; 
    padding: 2.5% 10px; 
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    color: rgba(255, 255, 238, 1);
  }
}

.chat-mobile-Recipe{
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
  height: 30px;
  border-radius: 10px;
  border: none!important;
  cursor: pointer;
  text-align: left;
  justify-content: flex-start;
}

.chat-mobile-Recipe {
  text-align: left;
  margin-top: 10%;
}
.mobile-recipe-Recipe{
  text-align: left;
  background-color: #e9EFDF!important;
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
  height: 30px;
  border-radius: 10px;
  border: none!important;
  cursor: pointer;
  text-align: left;
  justify-content: flex-start;
  border: 1px solid #e9efdf;
}
.chat-mobile-Recipe img, .mobile-recipe-Recipe img {
  width: 30px!important;
  height: 30px!important;
  margin-right: 10px;
  left: 10%;
  position: absolute;
}
.chat-mobile-Recipe h2, .mobile-recipe-Recipe h2 {
  color: #1d370b;
  margin: 0;
  font-size: 20px;
  text-align: left;
  left: 25%;
  position: absolute;
}
.store-recipe-title-mobile{
  max-width: 330px;
  display: flex;
  /* background-color: black; */
}
.edit-mobile .edit-text{
  position: absolute;
  right: 5%;
  font-size: 20px;
}
.store-recipe-title-mobile .store-text{
  position:absolute;
  margin-left: -8%;
  font-size: 16px;
  background-color: #56764B;
  color: white;
  padding: 8px;
  border-radius: 20px;
  width: 30%;
  text-align: center;
}
.light-mode .store-recipe-title-mobile{
  margin-left: 10%;
  font-size:20px;
  /* margin-top: 1%; */
  left: -80px;
}
.dark-mode .store-recipe-title-mobile{
  margin-left: 10%;
  font-size:20px;
  /* margin-top: 3%; */
  left: -80px;
}

.store-recipe-menu-mobile{
  flex-direction: column;
  margin-top: 15%;
  /* padding: 5px; */
  /* margin-left: 10%; */
  /* margin-right: 8%; */
  font-size: 20px!important;
}

.line-title-Recipe {
  height: 1.5px; 
  margin-top: -5%; /*저장한 레시피와의 공간 줄이기 위함*/
  background: linear-gradient(to right,#56764B,#56764B);
  margin-left: -9%;
  width: 105%; 
  border-radius: 5px;
  border: none; 
}
/* .recommend-recipe-mobile{
  overflow-y: auto;
} */
.light-mode .recommend-recipe-mobile{
  text-align: left;
  margin-left: 10%;
  margin-top: 10%;
  left: -80px;
}
.light-mode .recommend-recipe-mobile .today-text{
  margin-left: -8%;
  font-size: 13px;
  background-color: #56764B;
  color: white;
  padding: 3%;
  border-radius: 20px;
  width: fit-content;
  text-align: center;
  margin-bottom: 5%;
}
.line-title-mobile {
  margin-top: 1%;
  height: 3px; 
  background: linear-gradient(to right ,#56764B,#56764B);
  /* margin-left: -5%; */
  width: 95%; 
  border-radius: 5px;
  border: none; 
}
.recipe-item-container-mobile{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recommend-recipe-menu-mobile{
  flex-direction: column;
  text-align: left;
  font-size: 18px;
}
.recommend-recipe-menu-mobile h5{
  margin-bottom: 10%;
}
.recommend-recipe-item-mobile::before{
  content: '• ';
}
.recommend-recipe-item-mobile{
  height: fit-content;
  background-color: none;
  width: fit-content;
  padding: 5px;
  margin-bottom: 6px;
  border-radius: 5px;
}

.mobile-recipe-content{
  overflow-y: scroll;
  position: relative;
}
.recipe-item-mobile::before{
  content: '• ';
}
.recipe-item-mobile{
  margin-bottom: 6px;
  border-radius: 5px;
}
.recipe-item-container-mobile {
  flex-direction: column;
  position: relative;
  margin: 5px 0;
  text-align: left;
  align-items: flex-start;
}

.recipe-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 165%;
  /* background-color: #2B2B2B; */
}
.recipe-item-wrapper img{
  position: absolute;
  right: 0!important;
}
.remove-recipe-button-mobile {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  /* right: 0!important; */
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-recipe-button-mobile img{
  cursor: pointer;
}
.remove-recipe-button-mobile img:hover{
  /* border: none; */
  /* padding: 0px 10px; */
  /* margin-left: 250%; */
  cursor: pointer;
}
.minus-icon {
  width: 30px;
  height: 30px;
}

.recipe-box {
  position: relative;
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 0.3s ease-out;
}

.recipe-box.expanded {
  max-height: 500px; 
  overflow-y: scroll;
  background-color: #e9efdf;
  padding-left: 3%;
}
.emotion-text-mobile{
  font-size: 13px;
  display: flex;
  position: relative;
  margin-bottom: 2%;
}
