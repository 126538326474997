/* h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
} */

ul {
  list-style-type: disc;
}

li {
  margin: 0;
  padding: 0;
}
/* ol{
  margin: 0;
} */
/* h1 + ul, h2 + ul, h3 + ul, h4 + ul, h5 + ul, h6 + ul {
  margin-top: 0;
} */

ul > li {
  margin-top: 0;
}
body {
  margin: 0; 
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "cookie";
  text-align: center;
  background-image: url('../BackGround4.png');
  background-color: #2a2a2a;
  background-repeat: no-repeat;
  /* justify-items: center; */
  /* background-size: cover;
  background-position: center; */
  background-size: 200vh;
}

.logo1 {
  width: 100%;
  margin-top: 20%;
}

.chat-container {
  font-family: 'cookie';
  max-width: 100%;
  /* margin: 1% auto; */
  border: none;
  /* border-radius: 35px; */
  background-color: rgba(250, 250, 250, 0.8);
  display: flex;
  flex-direction: row;
  height: 100vh;
  color: #2a2a2a;
  position: relative;
  box-shadow: 0 4px 80px rgba(11, 11, 11, 1); 
  scrollbar-width: thin;
  scrollbar-color: #56764B #e9EFDF;
}
.dark-mode .chat-container{
  scrollbar-color: #2b2b2b #3a3a3a;
  transition: filter 2s;
}

.light-mode .left-half {
  background-color: #56764B;
  transition: filter 2s;
}

.dark-mode .left-half {
  background-color: #2a2a2a;
  transition: filter 2s;
}
.light-mode .right-half-Recipe {
  background-color: rgb(233, 239, 223,1);
  transition: filter 2s;
}

.dark-mode .right-half-Recipe {
  background-color: #3a3a3a;
  transition: filter 2s;
}

.left-half {
  width: 15vh;
  left: 0;
  height: 100vh;
  /* border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; */
  align-items: flex-start;
  text-align: center;
  flex-direction: column;
}
.middle-container .h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
} 
.light-mode .middle-container{
  text-align: left;
  left: 10px;
  background-color: #1D3708;
  width: 30%;
  flex-direction: column;
  color: white;
}
/* .recipe-container{
  padding: auto;
} */
.dark-mode .middle-container{
  text-align: left;
  left: 10px;
  background-color: #1a1a1a;
  width: 30%;
  flex-direction: column;
  color: white;
}
.line-title{
  margin-right: 5%;
  width: 95;
  margin-left: -7%;
}
.line-title-2{
  margin-right: 5%;
  width: 95;
  margin-left: -7%;
}
.light-mode .store-recipe-title{
  margin-left: 10%;
  font-size:30px;
  margin-top: 3%;
  left: -80px;
}
.dark-mode .store-recipe-title{
  margin-left: 10%;
  font-size:30px;
  margin-top: 3%;
  left: -80px;
}
.edit{
  text-align: right;
  margin-right: 8%;
  cursor: pointer;
}
.edit:hover{
  margin-top: -2%;
  font-size: 40px;
}
.recipe-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.remove-recipe-button{
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: -20px;
}
.remove-recipe-button img{
  height: 50px;
  width: 50px;
}
.remove-recipe-button img:hover{
  border: none;
  padding: 0px 10px;
  margin-left: 10px;
  cursor: pointer;
}
.emotion-text{
  margin-top: 2%;
  font-size: 17px;
  margin-left: 3%;
}
.light-mode .recommend-recipe{
  margin-left: 10%;
  font-size:30px;
  margin-top: 5%;
  left: -80px;
}
.dark-mode .recommend-recipe{
  margin-left: 10%;
  font-size:30px;
  margin-top: 10%;
  left: -80px;
}
.store-recipe-menu{
  text-align: left;
  margin-top: 8%;
  margin-left: 10%;
  margin-right: 8%;
  font-size: 30px;
}
.recipe-item-store{
  margin-bottom: 20px; 
  font-size: 24px;
  cursor: pointer;
}
.recipe-item-store:hover{
  transform: scale(1.1);
  margin-left: 10px;
}
.recipe-item {
  width: fit-content;
  padding: 5px;
  margin-bottom: 20px; 
  font-size: 24px;
  cursor: pointer;
}
.recipe-item:hover{
  transform: scale(1.1);
  margin-left: 10px;
}
.right-half-Recipe {
  width: 70%;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: left;
  /* padding-top: 30px; */
  padding-left:30px;
  font-size: 28px;
  padding-right: 30px;
  padding-bottom:30px;
}

.chat {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  height: 100%;
}

.icon_chat_Recipe.selected,
.icon_archive_Recipe.selected{
  transition: filter 1.5s ease, opacity 1.5s ease;
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
  background-color: #56764B;
}

.light-mode .icon_chat_Recipe {
  padding: 30px;
  transition: filter 1.5s ease, opacity 1.5s ease;

}
.dark-mode .icon_chat_Recipe {
  padding: 30px;
  transition: filter 1.5s ease, opacity 1.5s ease;
}
.light-mode .icon_chat_Recipe:hover {
  filter:none;
}
.dark-mode .icon_chat_Recipe{
  filter: none;
}
.dark-mode .icon_chat_Recipe:hover {
  filter: none;
}

.icon_archive_Recipe {
  transition: filter 1.5s ease, opacity 1.5s ease;
  padding: 25px;
}
.light-mode .icon_archive_Recipe {
  background-color: #1D3708!important;
  transition: filter 1.5s ease, opacity 1.5s ease;
  padding: 25px;
}
.light-mode .icon_archive_Recipe:hover {
  background-size: 10%;
  padding:25px
}
.dark-mode .icon_archive_Recipe{
  background-color: #1a1a1a;
}
/* .dark-mode .icon_archive_Recipe:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
} */
.icon_mode {
  display: flex;
  align-items: center;
}
.light-mode .setting-menu{
  align-items: flex-start;
  flex-direction: column;
  position:absolute;
  bottom: 9%; 
  left : 8%;
  width: 10%;
  height: 12%;
  background: #56764B;
  border: none;
  border-radius: 10px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000; 
}
.dark-mode .setting-menu{
  align-items: flex-start;
  flex-direction: column;
  position:absolute;
  bottom: 9%; 
  left : 8%;
  width: 10%;
  height: 12%;
  background: #2a2a2a;
  border: none;
  border-radius: 10px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000; 
}
.light-mode .password_menu{
  position: inherit;
  margin-top: 6%;
  left: 1%;
  background-color: #56764B;
  border: none;
  border-radius: 15px;
  font-family: cookie;
  width: 97%;
  height: 50%;
  color : white;
  font-size:23px;
}
.light-mode .password_menu:hover{
  background-color: #1D3708;
}
.dark-mode .password_menu{
  position: inherit;
  margin-top: 6%;
  left: 1%;
  background-color: #1a1a1a;
  border: none;
  border-radius: 15px;
  font-family: cookie;
  width: 97%;
  height: 50%;
  color : white;
  font-size:23px;
}
.dark-mode .password_menu:hover{
  background-color: #6a6a6a;
}
.icon_settings{
  position: absolute;
  transition: filter 1.5s ease, opacity 1.5s ease;
  margin-left: 10px;
  padding: 30px;
  margin-top: 300px;
  bottom:100px;
}
.light-mode .icon_settings:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
  padding: 30px;
}
.dark-mode .icon_settings:hover{
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
  padding: 30px;
}

.icon_exit {
  position: absolute;
  bottom: 0px;
  padding: 30px;
  margin-left: 10px;
  /* display: inline-block; */
  transition: filter 1.5s ease, opacity 1.5s ease;
}
.light-mode .icon_exit:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(74%) saturate(1250%) hue-rotate(64deg) brightness(80%) contrast(103%);
}

.dark-mode .icon_exit:hover {
  filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(17%) contrast(20%);
}

.chating-container {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: 10px;
  margin-left: 15px;
  padding: 30px 30px 0 30px;
}

.light-mode .input-container {
  background-color: #e9EFDF;
  transition: filter 2s;
}

.dark-mode .input-container {
  background-color: #3a3a3a;
  transition: filter 2s;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 1%;
  padding: 0px 20px;
  flex-shrink: 0;
}
.messages {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 90%;
  margin-bottom: 10%;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.light-mode .message.user {
  background-color: #56764B;
  transition: filter 2s;
}

.dark-mode .message.user {
  background-color: #2a2a2a;
  color:rgb(237, 237, 237);
  transition: filter 2s;
}

.message.user {
  position: relative;
  margin-left: 60%;
  right: 3%;
  text-align: left;
  padding: 13px;
  background-color: #56764B;
  border-radius: 20px;
  font-size: 18px;
  width: 35%;
  max-width: 40%;
  color: white;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.light-mode .message.ai {
  background-color: #e9EFDF;
  transition: filter 2s;
}

.dark-mode .message.ai {
  background-color: #3a3a3a;
  color:rgb(219, 219, 219);
  transition: filter 2s;
}

.message.ai {
  margin-top: 5%;
  margin-bottom: 10px;
  padding: 13px;
  border-radius: 20px;
  font-size: 20px;
  width: 90%;
  max-width: none;
  color: black;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.dark-mode .spinner {
  border-left-color: aliceblue;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); 
  border-left-color: #22a6b3; 
  border-radius: 50%; 
  width: 24px; 
  height: 24px; 
  animation: spin 1s linear infinite; 
  display: inline-block; 
  margin-top: 5px; 
  background: transparent;
  box-shadow: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
